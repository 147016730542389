import gql from "graphql-tag";

let App = {};
App.fragments = {
    attributes: gql`
        fragment AppFragment on App {
            id
            name
            handler
            type
            category
            created_at
            updated_at
            status
        }
    `,

    quickbook: gql`
        fragment QuickBookFragment on QuickBook {
            access_token
            expires_in
            refresh_token
            token_type
            x_refresh_token_expires_in
            realmid
        }
    `,

    facebook_catalog: gql`
        fragment FacebookCatalogFragment on FacebookCatalog {
            facebook_catalog_url
        }
    `,
    metafield: gql`
        fragment MetafieldFragment on Metafield {
            metafield
        }
    `,
    blaze: gql`
        fragment BlazeFragment on Blaze {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            auth_key
            dev_auth_key
            partner_key
            dev_partner_key
            sandbox
        }
    `,

    leaflogix: gql`
        fragment LeafLogixFragment on LeafLogix {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            internal_api_key
        }
    `,

    onfleet: gql`
        fragment OnFleetFragment on OnFleet {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            user
            sandbox
            test_user
            auto_assign
            auto_assign_mode
            merchants {
                store_id
                merchant
            }
        }
    `,

    cova: gql`
        fragment CovaFragment on Cova {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            client_id
            client_secret
            username
            password
            company_id
        }
    `,

    treez: gql`
        fragment TreezFragment on Treez {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            automatic_approval
            sandbox
            credentials {
                active
                default_store
                store_id
                store_name
                client_id
                api_key
                dispensary_name
                headless_client_id
                headless_client_secret
            }
        }
    `,

    dutchie: gql`
        fragment DutchieFragment on Dutchie {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            credentials {
                store_name
                api_key
                secret_api_key
                retailer_id
            }
        }
    `,

    jane: gql`
        fragment JaneFragment on Jane {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            app_id
            api_key
            store_id
            product_index
            reviews_index
            stores_index
            client_id
            client_secret
            operation_client_id
            operation_client_secret
            host
            auto_migrate_user
        }
    `,
    berbix: gql`
        fragment BerbixFragment on Berbix {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            secret_key
            template
        }
    `,
    idscan: gql`
        fragment IDScanFragment on IDScan {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            secret_key
            license_key
        }
    `,
    redirect: gql`
        fragment RedirectFragment on Redirect {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            links
        }
    `,
    minZipByCode: gql`
        fragment MinZipByCodeFragment on MinZipByCode {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            locations {
                name
                zips
                amount
            }
        }
    `,
    klaviyo: gql`
        fragment KlaviyoFragment on Klaviyo {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            used_for_subscription
            lists {
                id
                name
            }
            private_key
        }
    `,
    omnisend: gql`
        fragment OmnisendFragment on Omnisend {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            used_for_subscription
            api_key
        }
    `,
    alpineIQ: gql`
        fragment AlpineIQFragment on AlpineIQ {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            used_for_subscription
            used_for_rewards
            api_key
            user_id
            favorites_stores {
                name
                id
            }
            notify_by_sms
            campaign_id
            order_confirm_sms_message
            order_ready_sms_message
            order_canceled_sms_message
            order_completed_sms_message
        }
    `,
    iterable: gql`
        fragment IterableFragment on Iterable {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            used_for_subscription
            api_key
            settings {
                campaign_id
                event_type
                schedule_send_email_hours
                event_name
            }
        }
    `,
    feefo: gql`
        fragment FeefoFragment on Feefo {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            used_for_subscription
            merchant_identifier
        }
    `,
    swifter: gql`
        fragment SwifterFragment on Swifter {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            credentials {
                swifter_sandbox: sandbox
                swifter_client_id: client_id
                swifter_client_secret: client_secret
                swifter_auth_host: auth_host
                swifter_api_host: api_host
                swifter_store_provider_id: store_provider_id
            }
        }
    `,
    stronghold: gql`
        fragment StrongholdFragment on Stronghold {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            credentials {
                stronghold_sh_secret_key: sh_secret_key
                stronghold_sh_integration_key: sh_integration_key
                stronghold_api_host: api_host
                stronghold_store_provider_id: store_provider_id
            }
        }
    `,
    aeropay: gql`
        fragment AeropayFragment on Aeropay {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            credentials {
                aeropay_sandbox: sandbox
                aeropay_host: host
                aeropay_api_key: api_key
                aeropay_api_secret: api_secret
                aeropay_merchant_id: merchant_id
                aeropay_store_provider_id: store_provider_id
            }
        }
    `,
    surfside: gql`
        fragment SurfsideFragment on Surfside {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            active
            account_id
            source_id
            channel_id
        }
    `,
    listTrack: gql`
        fragment ListTrackFragment on ListTrack {
            id
            name
            handler
            type
            category
            status
            created_at
            updated_at
            client_app_id: client_id
            client_app_secret: client_secret
            data_client_id
            data_client_secret
            used_for_subscription
            list_id
            order_fields {
                id
                name
            }
            password_fields {
                id
                name
            }
            store_fields {
                id
                name
            }
            messages {
                message_id
                event_type
            }
            subscribe_lists {
                id
                name
            }
            subscribe_fields {
                id
                name
            }
            sources {
                id
                type
                event_id
            }
        }
    `,
};

export const QUERY_SYSTEM_APP_LIST = gql`
    query listApps {
        listApps {
            ...AppFragment
            ... on Blaze {
                ...BlazeFragment
            }
            ... on LeafLogix {
                ...LeafLogixFragment
            }
            ... on OnFleet {
                ...OnFleetFragment
            }
            ... on Cova {
                ...CovaFragment
            }
            ... on Treez {
                ...TreezFragment
            }
            ... on Dutchie {
                ...DutchieFragment
            }
            ... on Jane {
                ...JaneFragment
            }
            ... on Berbix {
                ...BerbixFragment
            }
            ... on IDScan {
                ...IDScanFragment
            }
            ... on Redirect {
                ...RedirectFragment
            }
            ... on MinZipByCode {
                ...MinZipByCodeFragment
            }
            ... on Klaviyo {
                ...KlaviyoFragment
            }
            ... on Omnisend {
                ...OmnisendFragment
            }
            ... on AlpineIQ {
                ...AlpineIQFragment
            }
            ... on Iterable {
                ...IterableFragment
            }
            ... on Feefo {
                ...FeefoFragment
            }
            ... on Swifter {
                ...SwifterFragment
            }
            ... on Stronghold {
                ...StrongholdFragment
            }
            ... on Aeropay {
                ...AeropayFragment
            }
            ... on Surfside {
                ...SurfsideFragment
            }
            ... on ListTrack {
                ...ListTrackFragment
            }
        }
    }
    ${App.fragments.attributes}
    ${App.fragments.blaze}
    ${App.fragments.leaflogix}
    ${App.fragments.onfleet}
    ${App.fragments.cova}
    ${App.fragments.treez}
    ${App.fragments.dutchie}
    ${App.fragments.jane}
    ${App.fragments.berbix}
    ${App.fragments.idscan}
    ${App.fragments.redirect}
    ${App.fragments.minZipByCode}
    ${App.fragments.klaviyo}
    ${App.fragments.omnisend}
    ${App.fragments.alpineIQ}
    ${App.fragments.iterable}
    ${App.fragments.feefo}
    ${App.fragments.swifter}
    ${App.fragments.stronghold}
    ${App.fragments.aeropay}
    ${App.fragments.surfside}
    ${App.fragments.listTrack}
`;

export const QUERY_LIST_STORE_APPS = gql`
    query listStoreApps(
        $account_id: String!
        $store_id: String!
        $category: AppCategoryType
    ) {
        listStoreApps(
            account_id: $account_id
            store_id: $store_id
            category: $category
        ) {
            ...AppFragment
            ... on QuickBook {
                ...QuickBookFragment
            }
            ... on FacebookCatalog {
                ...FacebookCatalogFragment
            }
            ... on Metafield {
                ...MetafieldFragment
            }
            ... on Blaze {
                ...BlazeFragment
            }
            ... on OnFleet {
                ...OnFleetFragment
            }
            ... on LeafLogix {
                ...LeafLogixFragment
            }
            ... on Treez {
                ...TreezFragment
            }
            ... on Cova {
                ...CovaFragment
            }
            ... on Dutchie {
                ...DutchieFragment
            }
            ... on Jane {
                ...JaneFragment
            }
            ... on Berbix {
                ...BerbixFragment
            }
            ... on IDScan {
                ...IDScanFragment
            }
            ... on Redirect {
                ...RedirectFragment
            }
            ... on MinZipByCode {
                ...MinZipByCodeFragment
            }
            ... on Klaviyo {
                ...KlaviyoFragment
            }
            ... on Omnisend {
                ...OmnisendFragment
            }
            ... on AlpineIQ {
                ...AlpineIQFragment
            }
            ... on Iterable {
                ...IterableFragment
            }
            ... on Feefo {
                ...FeefoFragment
            }
            ... on Swifter {
                ...SwifterFragment
            }
            ... on Stronghold {
                ...StrongholdFragment
            }
            ... on Aeropay {
                ...AeropayFragment
            }
            ... on Surfside {
                ...SurfsideFragment
            }
            ... on ListTrack {
                ...ListTrackFragment
            }
        }
    }
    ${App.fragments.attributes}
    ${App.fragments.quickbook}
    ${App.fragments.facebook_catalog}
    ${App.fragments.metafield}
    ${App.fragments.blaze}
    ${App.fragments.onfleet}
    ${App.fragments.leaflogix}
    ${App.fragments.cova}
    ${App.fragments.treez}
    ${App.fragments.dutchie}
    ${App.fragments.jane}
    ${App.fragments.berbix}
    ${App.fragments.idscan}
    ${App.fragments.redirect}
    ${App.fragments.minZipByCode}
    ${App.fragments.klaviyo}
    ${App.fragments.omnisend}
    ${App.fragments.alpineIQ}
    ${App.fragments.iterable}
    ${App.fragments.feefo}
    ${App.fragments.swifter}
    ${App.fragments.stronghold}
    ${App.fragments.aeropay}
    ${App.fragments.surfside}
    ${App.fragments.listTrack}
`;

export const QUERY_GET_SYSTEM_APP = gql`
    query getApp($app_id: String!) {
        getApp(app_id: $app_id) {
            ...AppFragment
            ... on Blaze {
                ...BlazeFragment
            }
            ... on OnFleet {
                ...OnFleetFragment
            }
            ... on LeafLogix {
                ...LeafLogixFragment
            }
            ... on Cova {
                ...CovaFragment
            }
            ... on Treez {
                ...TreezFragment
            }
            ... on Dutchie {
                ...DutchieFragment
            }
            ... on Jane {
                ...JaneFragment
            }
            ... on Berbix {
                ...BerbixFragment
            }
            ... on IDScan {
                ...IDScanFragment
            }
            ... on Redirect {
                ...RedirectFragment
            }
            ... on MinZipByCode {
                ...MinZipByCodeFragment
            }
            ... on Klaviyo {
                ...KlaviyoFragment
            }
            ... on Omnisend {
                ...OmnisendFragment
            }
            ... on AlpineIQ {
                ...AlpineIQFragment
            }
            ... on Iterable {
                ...IterableFragment
            }
            ... on Feefo {
                ...FeefoFragment
            }
            ... on Swifter {
                ...SwifterFragment
            }
            ... on Stronghold {
                ...StrongholdFragment
            }
            ... on Aeropay {
                ...AeropayFragment
            }
            ... on Surfside {
                ...SurfsideFragment
            }
            ... on ListTrack {
                ...ListTrackFragment
            }
        }
    }
    ${App.fragments.attributes}
    ${App.fragments.blaze}
    ${App.fragments.onfleet}
    ${App.fragments.leaflogix}
    ${App.fragments.cova}
    ${App.fragments.treez}
    ${App.fragments.dutchie}
    ${App.fragments.jane}
    ${App.fragments.berbix}
    ${App.fragments.idscan}
    ${App.fragments.redirect}
    ${App.fragments.minZipByCode}
    ${App.fragments.klaviyo}
    ${App.fragments.omnisend}
    ${App.fragments.alpineIQ}
    ${App.fragments.iterable}
    ${App.fragments.feefo}
    ${App.fragments.swifter}
    ${App.fragments.stronghold}
    ${App.fragments.aeropay}
    ${App.fragments.surfside}
    ${App.fragments.listTrack}
`;

export const MUTATION_CREATE_SYSTEM_APP = gql`
    mutation createApp($input: SystemAppInput!) {
        createApp(input: $input) {
            ...AppFragment
        }
    }
    ${App.fragments.attributes}
`;

export const MUTATION_UPDATE_SYSTEM_APP = gql`
    mutation updateApp($input: SystemAppInput!) {
        updateApp(input: $input) {
            ...AppFragment
        }
    }
    ${App.fragments.attributes}
`;

export const MUTATION_REMOVE_SYSTEM_APP = gql`
    mutation deleteCategory(
        $account_id: String!
        $store_id: String!
        $input: SystemAppInput!
    ) {
        deleteCategory(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_ACTIVATE_APP = gql`
    mutation activateAppForStore(
        $account_id: String!
        $store_id: String!
        $input: SystemAppInput!
    ) {
        activateAppForStore(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
            name
        }
    }
`;

export const MUTATION_DEACTIVE_APP = gql`
    mutation deactivateAppForStore(
        $account_id: String!
        $store_id: String!
        $input: SystemAppInput!
    ) {
        deactivateAppForStore(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...AppFragment
        }
    }
    ${App.fragments.attributes}
`;

// QuickBook
export const MUTATION_UPDATE_QUICKBOOK_APP = gql`
    mutation updateQuickBookApp(
        $account_id: String!
        $store_id: String!
        $input: QuickBookInput!
    ) {
        updateQuickBookApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// FacebookCatalog
export const MUTATION_UPDATE_FACEBOOKCATALOG_APP = gql`
    mutation updateFacebookCatalogApp(
        $account_id: String!
        $store_id: String!
        $input: FacebookCatalogInput!
    ) {
        updateFacebookCatalogApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_CREATE_FACEBOOKCATALOG_APP = gql`
    mutation createFacebookCatalogProducts(
        $account_id: String!
        $store_id: String!
    ) {
        createFacebookCatalogProducts(
            account_id: $account_id
            store_id: $store_id
        )
    }
`;

// Authorize Net
export const MUTATION_UPADTE_AUTHORIZE_NET_APP = gql`
    mutation updateAuthorizeNetApp(
        $account_id: String!
        $store_id: String!
        $input: AuthorizeNetInput!
    ) {
        updateAuthorizeNetApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// PayPal
export const MUTATION_UPDATE_PAYPAL_APP = gql`
    mutation updatePayPalApp(
        $account_id: String!
        $store_id: String!
        $input: PayPalInput!
    ) {
        updatePayPalApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Stripe
export const MUTATION_UPDATE_STRIPE_APP = gql`
    mutation updateStripeApp(
        $account_id: String!
        $store_id: String!
        $input: StripeInput!
    ) {
        updateStripeApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Metafield
export const MUTATION_UPDATE_METAFIELD_APP = gql`
    mutation updateMetafieldApp(
        $account_id: String!
        $store_id: String!
        $input: MetafieldInput!
    ) {
        updateMetafieldApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Shippo
export const MUTATION_UPDATE_SHIPPO_APP = gql`
    mutation updateShippoApp(
        $account_id: String!
        $store_id: String!
        $input: ShippoInput!
    ) {
        updateShippoApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// WebPay
export const MUTATION_UPDATE_WEBPAY_APP = gql`
    mutation updateWebPayApp(
        $account_id: String!
        $store_id: String!
        $input: WebPayInput!
    ) {
        updateWebPayApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// BlindData
export const MUTATION_UPDATE_BLINDDATA_APP = gql`
    mutation updateBlindDataApp(
        $account_id: String!
        $store_id: String!
        $input: BlindDataInput!
    ) {
        updateBlindDataApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Measure Request
export const MUTATION_UPDATE_MEASURE_REQUEST_APP = gql`
    mutation updateMeasureRequestApp(
        $account_id: String!
        $store_id: String!
        $input: MeasureRequestInput!
    ) {
        updateMeasureRequestApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Chile Express
export const MUTATION_UPDATE_CHILE_EXPRESS_APP = gql`
    mutation updateChileExpressApp(
        $account_id: String!
        $store_id: String!
        $input: ChileExpressInput!
    ) {
        updateChileExpressApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Price Table
export const MUTATION_UPDATE_PRICE_TABLE_APP = gql`
    mutation updatePriceTableApp(
        $account_id: String!
        $store_id: String!
        $input: PriceTableInput!
    ) {
        updatePriceTableApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Blaze App
export const MUTATION_UPDATE_BLAZE_APP = gql`
    mutation updateBlazeApp(
        $account_id: String!
        $store_id: String!
        $input: BlazeInput!
    ) {
        updateBlazeApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// OnFleet App
export const MUTATION_UPDATE_ONFLEET_APP = gql`
    mutation updateOnFleetApp(
        $account_id: String!
        $store_id: String!
        $input: OnFleetInput!
    ) {
        updateOnFleetApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// LeafLogix App
export const MUTATION_UPDATE_LEAFLOGIX_APP = gql`
    mutation updateLeafLogixApp(
        $account_id: String!
        $store_id: String!
        $input: LeafLogixInput!
    ) {
        updateLeafLogixApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Cova App
export const MUTATION_UPDATE_COVA_APP = gql`
    mutation updateCovaApp(
        $account_id: String!
        $store_id: String!
        $input: CovaInput!
    ) {
        updateCovaApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Treez App
export const MUTATION_UPDATE_TREEZ_APP = gql`
    mutation updateTreezApp(
        $account_id: String!
        $store_id: String!
        $input: TreezInput!
    ) {
        updateTreezApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Dutchie App
export const MUTATION_UPDATE_DUTCHIE_APP = gql`
    mutation updateDutchieApp(
        $account_id: String!
        $store_id: String!
        $input: DutchieInput!
    ) {
        updateDutchieApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Jane App
export const MUTATION_UPDATE_JANE_APP = gql`
    mutation updateJaneApp(
        $account_id: String!
        $store_id: String!
        $input: JaneInput!
    ) {
        updateJaneApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// IdScan App
export const MUTATION_UPDATE_ID_SCAN_APP = gql`
    mutation updateIDScanApp(
        $account_id: String!
        $store_id: String!
        $input: IDScanInput!
    ) {
        updateIDScanApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Berbix App
export const MUTATION_UPDATE_BERBIX_APP = gql`
    mutation updateBerbixApp(
        $account_id: String!
        $store_id: String!
        $input: BerbixInput!
    ) {
        updateBerbixApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// Redirect App
export const MUTATION_UPDATE_REDIRECT_APP = gql`
    mutation updateRedirectApp(
        $account_id: String!
        $store_id: String!
        $input: RedirectInput!
    ) {
        updateRedirectApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

// MINZIPBYCODE App
export const MUTATION_UPDATE_MIN_ZIP_BY_CODE = gql`
    mutation updateMinZipByCodeApp(
        $account_id: String!
        $store_id: String!
        $input: MinByZipCodeInput!
    ) {
        updateMinZipByCodeApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_KLAVIYO = gql`
    mutation updateKlaviyoApp(
        $account_id: String!
        $store_id: String!
        $input: KlaviyoInput!
    ) {
        updateKlaviyoApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_OMNISEND = gql`
    mutation updateOmnisendApp(
        $account_id: String!
        $store_id: String!
        $input: OmnisendInput!
    ) {
        updateOmnisendApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_ALPINEIQ = gql`
    mutation updateAlpineIQApp(
        $account_id: String!
        $store_id: String!
        $input: AlpineIQInput!
    ) {
        updateAlpineIQApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_ITERABLE = gql`
    mutation updateIterableApp(
        $account_id: String!
        $store_id: String!
        $input: IterableInput!
    ) {
        updateIterableApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_FEEFO = gql`
    mutation updateFeefoApp(
        $account_id: String!
        $store_id: String!
        $input: FeefoInput!
    ) {
        updateFeefoApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_STRONGHOLD = gql`
    mutation updateStrongholdApp(
        $account_id: String!
        $store_id: String!
        $input: StrongholdInput!
    ) {
        updateStrongholdApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_AEROPAY = gql`
    mutation updateAeropayApp(
        $account_id: String!
        $store_id: String!
        $input: AeropayInput!
    ) {
        updateAeropayApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_SWIFTER = gql`
    mutation updateSwifterApp(
        $account_id: String!
        $store_id: String!
        $input: SwifterInput!
    ) {
        updateSwifterApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_SURFSIDE = gql`
    mutation updateSurfsideApp(
        $account_id: String!
        $store_id: String!
        $input: SurfsideInput!
    ) {
        updateSurfsideApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;

export const MUTATION_UPDATE_LISTTRACK = gql`
    mutation updateListTrackApp(
        $account_id: String!
        $store_id: String!
        $input: ListTrackInput!
    ) {
        updateListTrackApp(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            id
        }
    }
`;
