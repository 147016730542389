import React, { useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { Button, Col, Row } from "reactstrap";
import {
    useFormActions,
    useForm,
    useMeta,
    useNotification,
    useConfirm,
} from "@/hooks";
import Form from "../../base/components/form/Form";
import {
    MUTATION_UPDATE_STRONGHOLD,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import StrongholdCredentialForm from "./StrongholdCredentialForm";
import { useMutation } from "@apollo/react-hooks";
import { Table } from "@/components";

const StrongholdForm = ({ app, loading, stores }) => {
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [credentials, setCredentials] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const notify = useNotification();

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [mutate] = useMutation(MUTATION_UPDATE_STRONGHOLD, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({ ...inputs, credentials });
            notify(
                `Credential Stronghold Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Credential, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const formatCredentials = credentials => {
        if (!credentials) {
            return [];
        }
        return credentials?.map(c => ({
            sh_secret_key: c?.stronghold_sh_secret_key,
            sh_integration_key: c.stronghold_sh_integration_key,
            api_host: c.stronghold_api_host,
            store_provider_id: c.stronghold_store_provider_id,
        }));
    };

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                credentials: formatCredentials(inputs?.credentials),
                type: "Stronghold",
            },
        },
    };

    const [confirm, launch] = useConfirm(() => {
        mutate(options);
    }, `Are You Sure You Want To Remove The Credential ?`);

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Stronghold",
        "",
        MUTATION_UPDATE_STRONGHOLD,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        () => {
            return true;
        },
        () => {
            return true;
        },
        "primary",
    );

    const columns = [
        {
            dataField: "stronghold_store_provider_id",
            text: "Store Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.stronghold_store_provider_id || ""}`}</div>;
            },
            sort: true,
        },
        {
            dataField: "stronghold_sh_secret_key",
            text: "Secret Key",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.stronghold_sh_secret_key || ""}`}</div>;
            },
        },
        {
            dataField: "stronghold_sh_integration_key",
            text: "Integration key",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>{`${row.stronghold_sh_integration_key || ""}`}</div>
                );
            },
        },
        {
            dataField: "stronghold_api_host",
            text: "Api Host",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.stronghold_api_host || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setCredentials(
                    _.filter(
                        inputs.credentials,
                        (i, index) => index !== rowIndex,
                    ),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    credentials: formatCredentials(array),
                    type: "Stronghold",
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const _credentials = (inputs && inputs.credentials) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "Stronghold",
                    subtitle: "Stronghold Information",
                }}
                sticky
                toolbar={[
                    <Button
                        className="ml-3"
                        color="secondary"
                        key="AddCredential"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Credential
                    </Button>,
                    createOrUpdateButton,
                ]}
            >
                {!user.loading ? (
                    <Form>
                        <Section>
                            {showForm && (
                                <StrongholdCredentialForm
                                    app={inputs}
                                    credentials={credentials}
                                    handleCancel={handleCancel}
                                    handlerUpdatingOrCreate={
                                        handlerUpdatingOrCreate
                                    }
                                    isCreate={isCreate}
                                    isUpdating={isUpdating}
                                    selectCredential={rowSelect}
                                    setCredentials={setCredentials}
                                    stores={stores}
                                />
                            )}
                            {/* TODO: note sure if this is the best way to create an space here*/}
                            <br></br>
                            <Row>
                                <Col>
                                    <SectionTitle md>Credentials</SectionTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table
                                        actions={actions}
                                        columns={columns}
                                        data={_credentials}
                                        keyField="store_id"
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </Section>
                    </Form>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </Portlet>
            {confirm}
        </>
    );
};

export default StrongholdForm;
