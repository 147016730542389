import React from "react";
import { Row, Col } from "reactstrap";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import Content from "../../base/layout/content/Content";
import { Portlet, Section } from "../../base/components/portlet";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { currencyFormat } from "../../utils/utils";
import {
    QUERY_GET_STAT,
    QUERY_GET_SNAPSHOT,
} from "../../apollo/module-operations/stat";
import useMeta from "../../base/hooks/useMeta.js";
import useNotification from "../../base/hooks/components/useNotification";
import Select from "../../base/components/form/select/Select";
import { snapShotOptions } from "../../config/constant";
import Table from "../../base/components/table/Table";
import useForm from "../../base/hooks/form/useForm";
import StatCard from "./components/StatCard";
const Dashboard = () => {
    const { user } = useMeta();
    const { selectValue } = useForm();
    const { loading, data, error } = useQuery(QUERY_GET_STAT, {
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
    });

    const [getSnapShot, { loading: loadingSnapShot, data: snapShotData }] =
        useLazyQuery(QUERY_GET_SNAPSHOT);

    const selectChange = e => {
        getSnapShot({
            notifyOnNetworkStatusChange: true,
            variables: {
                option: e.value,
                account_id: user.accountId,
                store_id: user.storeId,
            },
        });
    };

    const _stats = (data && _.omit(data.getStats, ["__typename"])) || {};

    const _snapshot =
        (snapShotData && _.omit(snapShotData.getSnapshot, ["__typename"])) ||
        {};

    const _growth = _.find(_stats.growth, stat => stat.type === "GROWTH");
    const _customers = _.find(_stats.growth, stat => stat.type === "CUSTOMER");
    const _orders = _.find(_stats.growth, stat => stat.type === "ORDER");
    const _revenue = _.find(_stats.growth, stat => stat.type === "REVENUE");

    const _statsnapshot = (_stats && _stats.snapshot) || {};
    const _filteredsnapshot = (_snapshot && _snapshot) || {};

    let subtotal = [];
    let total = [];
    let labels = [];

    if (_filteredsnapshot.subtotal !== undefined) {
        subtotal = _filteredsnapshot.subtotal;
        total = _filteredsnapshot.total;
        labels = _filteredsnapshot.labels;
    } else {
        subtotal = _statsnapshot.subtotal;
        total = _statsnapshot.total;
        labels = _statsnapshot.labels;
    }

    const _anual = (_stats && _stats.anual) || [];

    const _topProducts = _.map(
        (_stats && _stats.top_products) || [],
        product => {
            const pr = {
                ...product,
                price: currencyFormat(product.price).slice(1),
            };

            return pr;
        },
    );

    const _avgOrderTotal = _.find(
        _stats.growth,
        stat => stat.type === "AVERAGE_ORDER_TOTAL",
    );
    const _avgOrderSubtotal = _.find(
        _stats.growth,
        stat => stat.type === "AVERAGE_ORDER_SUBTOTAL",
    );

    const totalAnualSalesAmount = _stats?.total_sales_amount
        ? `$${_stats.total_sales_amount / 100}`
        : "";

    const snapShotTotalSales = _snapshot?.total_sales_amount || 0;

    const totalSnapshotSalesAmount = snapShotTotalSales
        ? `$${snapShotTotalSales || 0 / 100}`
        : "";

    const notify = useNotification();

    if (error)
        notify(
            `Error getting stats, If The Error Persist Please Contact Support.`,
            "error",
        );

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },

        {
            dataField: "title",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{cell}</div>;
            },
        },
        {
            dataField: "weight",
            text: "Weight",
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{(cell || 0).toFixed(2)}</div>;
            },
        },
        {
            dataField: "price",
            text: "Price",
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>${cell}</div>;
            },
        },
        {
            dataField: "qty",
            text: "Quantity",
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{cell}</div>;
            },
        },
    ];

    return (
        <Content className="dasboard__body">
            <Row className="dashboard__stats">
                <StatCard
                    loading={loading}
                    stat={_customers}
                    title="customers"
                    type="count"
                />
                <StatCard
                    loading={loading}
                    stat={_orders}
                    title="orders"
                    type="count"
                />
                <StatCard
                    loading={loading}
                    stat={_revenue}
                    title="revenue"
                    type="currency"
                />
                <StatCard
                    loading={loading}
                    stat={_growth}
                    title="growth"
                    type="currency"
                />
                <StatCard
                    loading={loading}
                    stat={_avgOrderSubtotal}
                    title="average_order_subtotal"
                    type="currency"
                />
                <StatCard
                    loading={loading}
                    stat={_avgOrderTotal}
                    title="average_order_total"
                    type="currency"
                />
            </Row>
            <Row>
                <Col md={6}>
                    <Portlet
                        className="dasboard__graphics"
                        header={{
                            title: "Total Sales Report View",
                            subtitle: totalAnualSalesAmount,
                            toolbar: (
                                <div
                                    style={{
                                        minWidth: 160,
                                    }}
                                />
                            ),
                        }}
                    >
                        {loading ? (
                            <div className="content-placeholder d-flex align-items-end h-100">
                                <div className="cp-w-xs cp-h-lg mr-3" />
                                <div className="cp-w-xs cp-h-md mr-3" />
                                <div className="cp-w-xs cp-h-sm mr-3" />
                            </div>
                        ) : (
                            <Bar
                                data={{
                                    labels: [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "Jun",
                                        "Jul",
                                        "Aug",
                                        "Sep",
                                        "Oct",
                                        "Nov",
                                        "Dec",
                                    ],
                                    datasets: [
                                        {
                                            label: "Product Sales",
                                            data: _.map(_anual, data => {
                                                return data.subtotal;
                                            }),
                                            backgroundColor: "#3B9415",
                                        },
                                        {
                                            label: "Products Sales, Tax and Shipping",
                                            data: _.map(_anual, data => {
                                                return data.total;
                                            }),
                                            backgroundColor: "#D5D9E6",
                                        },
                                    ],
                                }}
                                options={{
                                    legend: false,
                                    scales: {
                                        xAxes: [
                                            {
                                                stacked: true,
                                                categoryPercentage: 0.35,
                                                barPercentage: 0.7,
                                                gridLines: false,
                                                ticks: {
                                                    fontColor: "#505D6F",
                                                    fontSize: 12,
                                                    padding: 10,
                                                },
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                categoryPercentage: 0.35,
                                                barPercentage: 0.7,
                                                gridLines: {
                                                    color: "#EDEFF5",
                                                    drawBorder: false,
                                                    lineWidth: 0.5,
                                                    borderDash: [0],
                                                    zeroLineWidth: 0.5,
                                                    zeroLineColor: "#EDEFF5",
                                                    zeroLineBorderDash: [0],
                                                },
                                                ticks: {
                                                    // max: 100,
                                                    min: 0,
                                                    // stepSize: 20,
                                                    fontColor: "#505D6F",
                                                    fontSize: 12,
                                                    padding: 10,
                                                    callback: value =>
                                                        currencyFormat(value),
                                                },
                                            },
                                        ],
                                    },
                                    tooltips: {
                                        mode: "index",
                                        intersect: false,
                                        callbacks: {
                                            label: (context, datasets) =>
                                                `${
                                                    datasets.datasets[
                                                        context.datasetIndex
                                                    ].label
                                                }: ${currencyFormat(
                                                    context.yLabel,
                                                )}`,
                                        },
                                    },
                                }}
                            />
                        )}
                    </Portlet>
                </Col>
                <Col md={6}>
                    <Portlet
                        className="dasboard__graphics"
                        header={{
                            //noBorder: true,
                            title: "Sales Snapshot View",
                            subtitle: totalSnapshotSalesAmount,
                            toolbar: (
                                <div
                                    style={{
                                        minWidth: 160,
                                    }}
                                >
                                    <Select
                                        controlName="snapshotoption"
                                        defaultValue={selectValue(
                                            0,
                                            snapShotOptions,
                                        )}
                                        onChange={selectChange}
                                        options={snapShotOptions}
                                    />
                                </div>
                            ),
                        }}
                    >
                        {loading || loadingSnapShot ? (
                            <div className="content-placeholder d-flex align-items-end h-100">
                                <div className="cp-w-xs cp-h-lg mr-3" />
                                <div className="cp-w-xs cp-h-md mr-3" />
                                <div className="cp-w-xs cp-h-sm mr-3" />
                            </div>
                        ) : (
                            <Line
                                data={{
                                    labels: labels,
                                    datasets: [
                                        {
                                            label: "Product Sales",
                                            data: subtotal,
                                            backgroundColor: "#f2f6f2",
                                            borderWidth: 3,
                                            borderColor: "#3B9415",
                                        },
                                        {
                                            label: "Product Sales, Tax and Shipping",
                                            data: total,
                                            borderWidth: 3,
                                            borderDash: [5, 5],
                                            borderColor: "#D5D9E6",
                                            fill: false,
                                        },
                                    ],
                                }}
                                options={{
                                    legend: false,
                                    scales: {
                                        xAxes: [
                                            {
                                                categoryPercentage: 0.35,
                                                barPercentage: 0.7,
                                                gridLines: {
                                                    color: "#EDEFF5",
                                                    drawBorder: false,
                                                    lineWidth: 1.9,
                                                    borderDash: [3, 4],
                                                    zeroLineWidth: 1.9,
                                                    zeroLineColor: "#EDEFF5",
                                                    zeroLineBorderDash: [3, 4],
                                                },
                                                ticks: {
                                                    fontColor: "#505D6F",
                                                    fontSize: 12,
                                                    padding: 10,
                                                },
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                categoryPercentage: 0.35,
                                                barPercentage: 0.7,
                                                gridLines: false,
                                                ticks: {
                                                    // max: 100,
                                                    min: 0,
                                                    // stepSize: 20,
                                                    fontColor: "#505D6F",
                                                    fontSize: 12,
                                                    padding: 10,
                                                    callback: value =>
                                                        currencyFormat(value),
                                                },
                                            },
                                        ],
                                    },
                                    tooltips: {
                                        mode: "index",
                                        intersect: false,
                                        callbacks: {
                                            label: (context, datasets) =>
                                                `${
                                                    datasets.datasets[
                                                        context.datasetIndex
                                                    ].label
                                                }: ${currencyFormat(
                                                    context.yLabel,
                                                )}`,
                                        },
                                    },
                                }}
                            />
                        )}
                    </Portlet>
                </Col>
                <Col>
                    <Portlet
                        header={{
                            noBorder: true,
                            title: "Top Five Selling Products",
                        }}
                    >
                        {loading ? (
                            <div className="content-placeholder">
                                <div className="cp-w-xl cp-h-xl" />
                            </div>
                        ) : (
                            <Table
                                columns={columns}
                                data={_topProducts}
                                keyField="id"
                                loading={loading}
                            />
                        )}
                    </Portlet>
                </Col>
            </Row>
        </Content>
    );
};

export default Dashboard;
