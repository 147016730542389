import { useEffect, useState } from "react";
import { QUERY_TREEZ_HEADLESS_DISCOUNT, QUERY_LIST_STORE_APPS } from "@/data";
import { useMeta, useForm } from "@/hooks";
import { Content } from "@/layout";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Badge, Icon, Input, Portlet, Table } from "@/components";
import { TreezObjectModal } from "./components";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import Select from "../../base/components/form/select/Select";
import { paginationOptions } from "../../config/components/table";
import { NOT_AVAILABLE } from "@/config";

const TreezDiscount = () => {
    const { user } = useMeta();
    const [treezStores, setTreezStores] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const { inputs, selectChange, selectValue, setInputs } = useForm();

    const COLUMNS = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_primary">
                            {cell || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "method",
            text: "Method",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_secondary">
                            {cell || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "active",
            formatter: cell => {
                return (
                    <Badge
                        className={cell ? "status__success" : "status__error"}
                        label={cell ? "Yes" : "No"}
                    />
                );
            },
            text: "Active",
            sort: true,
        },
        {
            dataField: "amount",
            text: "Amount",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_primary">
                            {cell || 0}
                        </div>
                    </>
                );
            },
        },
    ];

    const { loading: loadingApps } = useQuery(QUERY_LIST_STORE_APPS, {
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            category: "ERP",
        },
        onCompleted: res => {
            const treezApp = res?.listStoreApps?.find(
                app => app.handler === "treez",
            );

            setTreezStores(
                treezApp?.credentials.map(credential => {
                    return {
                        label: credential.store_name,
                        value: credential.store_id,
                    };
                }) || [],
            );
            setInputs({
                ...inputs,
                store: treezApp?.credentials[0]?.store_id || null,
            });
        },
    });

    const [listTreezDiscounts, { loading, data, networkStatus }] = useLazyQuery(
        QUERY_TREEZ_HEADLESS_DISCOUNT,
        {
            skip: user.loading || loadingApps || !inputs.store,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                provider_id: inputs.store,
            },
        },
    );

    useEffect(() => {
        !loadingApps && inputs.store && listTreezDiscounts();
    }, [inputs.store]);

    const handleRowClick = element => {
        setIsModalOpen(!isModalOpen);
        setSelectedDiscount(element);
    };

    const rowEvents = {
        onClick: (_, row) => {
            handleRowClick(row);
        },
    };

    const handleSearch = e => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const _loading = loading || loadingApps || networkStatus === 4;

    const _data = data && data.getTreezHeadlessDiscounts;

    const filteredData = _data?.filter(
        item =>
            item.title.toLowerCase().includes(searchQuery) ||
            item.method.toLowerCase().includes(searchQuery),
    );

    return (
        <>
            <Content>
                <Portlet
                    className={"treez"}
                    header={{
                        title: "Treez Discounts",
                        subtitle: "All Treez Discount availables in the system",
                    }}
                    sticky
                    toolbar={[
                        <Select
                            className="treez__select-store ml-2"
                            controlName="store"
                            key="select_store"
                            onChange={selectChange}
                            options={treezStores}
                            placeholder="Store"
                            style={{ minWidth: "160px" }}
                            value={selectValue(inputs.store, treezStores)}
                        />,
                    ]}
                >
                    <div className="k-portlet__head-toolbar treez__input-search">
                        <div className="k-input-icon k-input-icon--left ">
                            <Input
                                bsSize="sm"
                                className={"treez__search"}
                                defaultValue={inputs.name}
                                name="name"
                                onChange={e => {
                                    handleSearch(e);
                                }}
                                placeholder="Search by discounts by name and method"
                            />
                            <span className="k-input-icon__icon k-input-icon__icon--left">
                                <span>
                                    <Icon icon="search" />
                                </span>
                            </span>
                        </div>
                    </div>
                    <Table
                        className="table table-hover"
                        columns={COLUMNS}
                        data={filteredData}
                        keyField={"id"}
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        rowEvents={rowEvents}
                    />
                </Portlet>
            </Content>
            <TreezObjectModal
                handleModalToggle={() => setIsModalOpen(false)}
                isModalOpen={isModalOpen}
                object={selectedDiscount}
                title="Discount Details"
            />
        </>
    );
};

export default TreezDiscount;
