import routes from "../config/routes/routes";
import { Auth } from "aws-amplify";
import moment from "moment";
import _ from "lodash";
import { NOT_AVAILABLE } from "../config/constant";

export const query = qs => {
    let params = {};

    let _qs = qs.substr(1);

    _qs = _.split(_qs, "&");

    _.forEach(_qs, v => {
        let keyValue = _.split(v, "=");

        params[keyValue[0]] = keyValue[1];
    });

    return params;
};

export const formatAddress = address => {
    let { address1, address2, city, province_code, country_code, zip } =
        address;

    address1 = address1 || "";
    address2 = address2 || "";
    city = city || "";
    province_code = province_code || "";
    country_code = country_code || "";
    zip = zip || "";

    let str = `${address1}${address2 ? ", " + address2 + "," : ""}${
        " " + city
    }${
        city && province_code ? "," : ""
    } ${province_code} ${zip} ${country_code}`;

    return str;
};

export const updateAccountAttribute = async (account_id, store_id, history) => {
    let user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
    });

    await Auth.updateUserAttributes(user, {
        "custom:ACCOUNT_ID": account_id,
        "custom:STORE_ID": store_id,
    });

    history.push(routes.SETTINGS.route);
    window.location.reload();
};

export const omit = options => {
    options = _.omit(options, [
        "variables.input.__typename",
        "variables.input.ignore_whitespace",
    ]);

    return options;
};

export const formatDiscountType = str => {
    str = str.toLowerCase().split("_");
    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
};

export const formatFileUploadFileName = (name, accountId, storeId) => {
    let fileName = `${moment().valueOf()}-${_.toLower(name).replace(
        /\s/g,
        "-",
    )}`;
    fileName = `${accountId}/${storeId}/${fileName}`;

    return fileName;
};

export const isInt = value => {
    return value.toString().indexOf(".") === -1;
};

export const isFloat = value => {
    return !isInt(value);
};

export const toCurrency = value => {
    if (!value) {
        return null;
    }

    return parseInt(_.replace(value, /[,.]/g, ""), 10);
};

export function currencyFormat(value) {
    const toFloat = value / 100;
    const int = parseInt(toFloat);
    return toFloat === int ? `$${int}` : `$${toFloat.toFixed(2)}`;
}

function convertTime(time) {
    if (!time || time.split(":").length === 1) {
        return time;
    }

    // replace first zero
    // and spaces
    time = time.replace(/\s/g, "").replace(/^0+/, "");

    // replace 00 from the mins
    const arr = time.split(":");
    if (arr[1].includes("00")) {
        time = `${arr[0]}${arr[1].replace("00", "")}`;
    }

    return time.toLowerCase();
}

export function timeWindowFormat(value) {
    if (!value) {
        return NOT_AVAILABLE;
    }

    if (value.split("-").length === 1) {
        return value;
    }

    const times = value.split("-");
    return `${convertTime(times[0])} - ${convertTime(times[1])}`;
}

export const currencyToStg = v => {
    if (!v) {
        return "0.00";
    }

    return (v / 100).toFixed(2);
};

export const formatUpdateOrderInput = inputs => {
    return {
        ...inputs,
        latitude: parseFloat(inputs.latitude),
        longitude: parseFloat(inputs.longitude),

        // currncy to int
        min_order_amount: toCurrency(inputs.min_order_amount),
        tax_ammont: toCurrency(inputs.tax_ammont),
        debit_card_fee: toCurrency(inputs.debit_card_fee),

        store_feature: _.map(_.get(inputs, "store_feature", []), feature => {
            return {
                ...feature,
                position: parseInt(feature.position, 10),
            };
        }),
        account_setting: inputs.account_setting,
        feature_flags: {
            ...inputs.feature_flags,
            treez_filter_sort:
                inputs.feature_flags?.treez_filter_sort?.toString() || "",
            treez_filters_title_mapping:
                inputs.feature_flags?.treez_filters_title_mapping?.toString() ||
                "",
            discount_minimum_threshold_value: toCurrency(
                inputs.feature_flags?.discount_minimum_threshold_value,
            ),
        },
    };
};

export const formatSetOrderInput = inputs => {
    return {
        ...inputs,
        min_order_amount: currencyToStg(inputs.min_order_amount),
        debit_card_fee: currencyToStg(inputs.debit_card_fee),
        tax_ammont: currencyToStg(inputs.tax_ammont),
        feature_flags: {
            ...inputs.feature_flags,
            discount_minimum_threshold_value: currencyToStg(
                inputs.feature_flags?.discount_minimum_threshold_value,
            ),
        },
    };
};

/*
export const currencyFormat = value => {
   return `$${value.toString().replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1.")}`;

}; */
