import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Spinner,
    Select,
} from "@/components";

const StrongholdCredentialForm = ({
    isCreate,
    handleCancel,
    app,
    selectCredential,
    setCredentials,
    isUpdating,
    handlerUpdatingOrCreate,
    stores,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "stronghold_store_provider_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store provider id is required.",
            },
            {
                field: "stronghold_sh_secret_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Secret Key is required.",
            },
            {
                field: "stronghold_sh_integration_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Integration key is required.",
            },
            {
                field: "stronghold_api_host",
                method: "isEmpty",
                validWhen: false,
                message: "This Api Host is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.credentials[selectCredential];
            setInputs({
                stronghold_sh_secret_key: objSelect?.stronghold_sh_secret_key,
                stronghold_sh_integration_key:
                    objSelect?.stronghold_sh_integration_key,
                stronghold_api_host: objSelect?.stronghold_api_host,
                stronghold_store_provider_id:
                    objSelect?.stronghold_store_provider_id,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let accounts = _.cloneDeep(app.credentials ?? []);

            if (isCreate) {
                accounts.push({
                    stronghold_sh_secret_key: inputs?.stronghold_sh_secret_key,
                    stronghold_sh_integration_key:
                        inputs?.stronghold_sh_integration_key,
                    stronghold_api_host: inputs?.stronghold_api_host,
                    stronghold_store_provider_id:
                        inputs?.stronghold_store_provider_id,
                });
            } else {
                accounts[selectCredential] = inputs;
            }
            setCredentials(accounts);
            handlerUpdatingOrCreate(accounts);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="p-4 bg-light">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Credential" : "Update Credential"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SectionTitle md>Credential Informations</SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store Provider Id</Label>
                            <Select
                                controlName="stronghold_store_provider_id"
                                invalid={
                                    validator.stronghold_store_provider_id
                                        .isInvalid
                                }
                                onChange={selectChange}
                                options={stores}
                                value={selectValue(
                                    inputs.stronghold_store_provider_id,
                                    stores,
                                )}
                            />
                            <FormFeedback>
                                {validator.stronghold_store_provider_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Api host</Label>
                            <Input
                                invalid={
                                    validator.stronghold_api_host.isInvalid
                                }
                                name="stronghold_api_host"
                                onChange={inputChange}
                                placeholder="Api Host"
                                value={inputs.stronghold_api_host}
                            />
                            <FormFeedback>
                                {validator.stronghold_api_host.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>SH secret key</Label>
                            <Input
                                invalid={
                                    validator.stronghold_sh_secret_key.isInvalid
                                }
                                name="stronghold_sh_secret_key"
                                onChange={inputChange}
                                placeholder="SH Secret Key"
                                value={inputs.stronghold_sh_secret_key}
                            />
                            <FormFeedback>
                                {validator.stronghold_sh_secret_key.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>SH integration key</Label>
                            <Input
                                invalid={
                                    validator.stronghold_sh_integration_key
                                        .isInvalid
                                }
                                name="stronghold_sh_integration_key"
                                onChange={inputChange}
                                placeholder="SH Integration Key"
                                value={inputs.stronghold_sh_integration_key}
                            />
                            <FormFeedback>
                                {
                                    validator.stronghold_sh_integration_key
                                        .message
                                }
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default StrongholdCredentialForm;
