import React, { useState } from "react";
import { Portlet, Section, SectionTitle, Table, Icon } from "@/components";
import {
    Button,
    Col,
    Row,
    FormGroup,
    Label,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import useFormActions from "../../base/hooks/form/useFormActions";
import { useMutation } from "@apollo/react-hooks";
import Input from "../../base/components/form/input/Input";
import {
    MUTATION_UPDATE_ALPINEIQ,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import {
    useConfirm,
    useForm,
    useMeta,
    useNotification,
    useValidator,
} from "@/hooks";
import FavoriteStoreAlpineIOForm from "./FavoriteStoresAlpineIOForm";
import { VARIABLES_TEMP_DEFAULT } from "config/constant";

const AlpineIOForm = ({ app, loading }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [favorites_stores, setFavorites_Stores] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const { inputs, setInputs, inputChange } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "user_id",
                method: "isEmpty",
                validWhen: false,
                message: "User Id is required.",
            },
            {
                field: "api_key",
                method: "isEmpty",
                validWhen: false,
                message: "Api Key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
        setFavorites_Stores(app.favorites_stores);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                favorites_stores,
                type: "AlpineIQ",
            },
        },
    };

    const [mutate] = useMutation(MUTATION_UPDATE_ALPINEIQ, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({
                ...inputs,
                favorites_stores,
            });
            notify(
                `Favorite Store Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Favorite Store, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(() => {
        mutate(options);
    }, `Are You Sure You Want To Remove The Favorite Store ?`);

    const { createOrUpdateButton } = useFormActions(
        "id",
        "AlpineIQ",
        "",
        MUTATION_UPDATE_ALPINEIQ,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    const columns = [
        {
            dataField: "id",
            text: "Store Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.id || ""}`}</div>;
            },
        },
        {
            dataField: "name",
            text: "Store Name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.name || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setFavorites_Stores(
                    _.filter(
                        inputs.favorites_stores,
                        (i, index) => index !== rowIndex,
                    ),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        if (!isValid()) {
            return;
        }

        setIsUpdating(true);

        setFavorites_Stores(array);
        options.variables.input.favorites_stores = array;
        mutate(options);
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const clipboardCopy = text => {
        navigator.clipboard.writeText(text);
        notify(`${text} is copied`, "success");
    };

    const _favorites_stores = (inputs && inputs.favorites_stores) || [];

    return (
        <>
            <Portlet
                header={{
                    title: "AlpineIQ",
                    subtitle: "AlpineIQ Information",
                }}
                sticky
                toolbar={[
                    createOrUpdateButton,
                    <Button
                        className="ml-3"
                        color="secondary"
                        key="addFavStore"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Store
                    </Button>,
                ]}
            >
                <Section>
                    <Row>
                        <Col>
                            <SectionTitle md>Information</SectionTitle>
                        </Col>
                    </Row>

                    <FormGroup row>
                        <Col sm={3}>
                            <Label
                                className="row-label"
                                style={{ marginTop: "10px" }}
                            >
                                Used for subscription
                            </Label>
                        </Col>
                        <Col>
                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.used_for_subscription
                                                ? "checked"
                                                : ""
                                        }
                                        name="used_for_subscription"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={3}>
                            <Label
                                className="row-label"
                                style={{ marginTop: "10px" }}
                            >
                                Used for rewards
                            </Label>
                        </Col>
                        <Col>
                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.used_for_rewards
                                                ? "checked"
                                                : ""
                                        }
                                        name="used_for_rewards"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label>Campaign Id</Label>
                            <Input
                                name="campaign_id"
                                onChange={inputChange}
                                placeholder="Campaign id"
                                value={inputs.campaign_id}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>
                            <Label>User Id</Label>
                            <Input
                                invalid={validator.user_id.isInvalid}
                                name="user_id"
                                onChange={inputChange}
                                placeholder="User id"
                                value={inputs.user_id}
                            />
                            <FormFeedback>
                                {validator.user_id.message}
                            </FormFeedback>
                        </Col>
                        <Col lg={6}>
                            <Label>Api Key</Label>
                            <Input
                                invalid={validator.api_key.isInvalid}
                                name="api_key"
                                onChange={inputChange}
                                placeholder="Api key"
                                value={inputs.api_key}
                            />
                            <FormFeedback>
                                {validator.api_key.message}
                            </FormFeedback>
                        </Col>
                    </FormGroup>
                    <Row>
                        <Col>
                            <SectionTitle md>Transactional SMS</SectionTitle>
                        </Col>
                    </Row>
                    <FormGroup row>
                        <Col md={3} sm={4}>
                            <Label
                                className="row-label"
                                style={{ marginTop: "10px" }}
                            >
                                Notify by SMS
                            </Label>
                        </Col>
                        <Col md={3} sm={3}>
                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.notify_by_sms
                                                ? "checked"
                                                : ""
                                        }
                                        name="notify_by_sms"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </Col>
                        <Col
                            md={6}
                            sm={5}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                className="btn__variables_template "
                                color="metal"
                                disabled={isOpen}
                                onClick={toggleModal}
                            >
                                View Variables
                            </Button>
                            <Modal
                                isOpen={isOpen}
                                size="lg"
                                style={{ marginTop: "120px" }}
                                toggle={toggleModal}
                            >
                                <ModalHeader toggle={toggleModal}>
                                    Available variables
                                </ModalHeader>
                                <ModalBody className="notification__tp_body">
                                    {VARIABLES_TEMP_DEFAULT.map(
                                        (item, index) => (
                                            <div key={index}>
                                                <h5>{item.label}</h5>
                                                <ul>
                                                    {item.value.map(
                                                        (subItem, subIndex) => (
                                                            <li
                                                                className="notification__variable"
                                                                key={subIndex}
                                                            >
                                                                <span>
                                                                    {subItem}
                                                                </span>
                                                                <Button
                                                                    className="notification__copy"
                                                                    onClick={() =>
                                                                        clipboardCopy(
                                                                            subItem,
                                                                        )
                                                                    }
                                                                    title="Copy POS product name"
                                                                >
                                                                    <Icon
                                                                        color="#727a94"
                                                                        icon="copy"
                                                                    />
                                                                </Button>
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        ),
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="secondary"
                                        onClick={toggleModal}
                                        style={{
                                            width: "140px",
                                            marginRight: "8px",
                                        }}
                                    >
                                        Close
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                    </FormGroup>
                    {inputs.notify_by_sms && (
                        <>
                            <FormGroup row>
                                <Col lg={6}>
                                    <Label>Order Confirm SMS</Label>
                                    <Input
                                        name="order_confirm_sms_message"
                                        onChange={inputChange}
                                        placeholder="Message"
                                        rows={2}
                                        type="textarea"
                                        value={inputs.order_confirm_sms_message}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label>Order Ready SMS</Label>
                                    <Input
                                        name="order_ready_sms_message"
                                        onChange={inputChange}
                                        placeholder="Message"
                                        rows={2}
                                        type="textarea"
                                        value={inputs.order_ready_sms_message}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={6}>
                                    <Label>Order Canceled SMS</Label>
                                    <Input
                                        name="order_canceled_sms_message"
                                        onChange={inputChange}
                                        placeholder="Message"
                                        rows={2}
                                        type="textarea"
                                        value={
                                            inputs.order_canceled_sms_message
                                        }
                                    />
                                </Col>
                                <Col lg={6}>
                                    <Label>Order Completed SMS</Label>
                                    <Input
                                        name="order_completed_sms_message"
                                        onChange={inputChange}
                                        placeholder="Message"
                                        rows={2}
                                        type="textarea"
                                        value={
                                            inputs.order_completed_sms_message
                                        }
                                    />
                                </Col>
                            </FormGroup>
                        </>
                    )}
                </Section>
                {showForm && (
                    <FavoriteStoreAlpineIOForm
                        app={inputs}
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                        isCreate={isCreate}
                        isUpdating={isUpdating}
                        selectStore={rowSelect}
                        setStore={setFavorites_Stores}
                    />
                )}
                <Section>
                    <Row>
                        <Col>
                            <SectionTitle md>Favorites Stores</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                actions={actions}
                                columns={columns}
                                data={_favorites_stores}
                                keyField="id"
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Section>
            </Portlet>
            {confirm}
        </>
    );
};

export default AlpineIOForm;
