import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_ID_SCAN_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const IdScanForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                secret_key: inputs.secret_key,
                license_key: inputs.license_key,
                type: "IDScan",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "secret_key",
                method: "isEmpty",
                validWhen: false,
                message: "This secret key is required.",
            },

            {
                field: "license_key",
                method: "isEmpty",
                validWhen: false,
                message: "This license key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Redirect",
        "",
        MUTATION_UPDATE_ID_SCAN_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Id Scan",
                subtitle: "Id Scan Information",
            }}
            sticky
            toolbar={createOrUpdateButton}
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col lg={6}>
                                <Label>License Key</Label>
                                <Input
                                    invalid={validator.license_key.isInvalid}
                                    name="license_key"
                                    onChange={inputChange}
                                    value={inputs.license_key}
                                />
                                <FormFeedback>
                                    {validator.license_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col lg={6}>
                                <Label>Secret Key</Label>
                                <Input
                                    invalid={validator.secret_key.isInvalid}
                                    name="secret_key"
                                    onChange={inputChange}
                                    value={inputs.secret_key}
                                />
                                <FormFeedback>
                                    {validator.secret_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default IdScanForm;
